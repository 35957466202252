@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
#app {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%; }

#micro_mian_child > div {
  width: 100%;
  height: 100%;
  overflow: hidden; }

:root {
  --children-left-bg: #1B2556;
  --title-bg: #2C316E;
  --menu-bg:#484c60
; }
